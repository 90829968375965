import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import UnderlineBtn from "../components/underlineBtn"

// Assets

import LeftTree from "./404/left-tree.png"
import RightTree from "./404/right-tree.png"
import House1 from "./404/mobile-house.png"
import House2 from "./404/desktop-house.png"
import NestaLogo from "./404/logo.inline.svg"

// Styles
import styles from "./404/404-styles.module.scss"

const NotFoundPage = () => {
  const [reveal, setReveal] = useState(false)

  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
    }, 1000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
    }
  }, [])

  return (
    <div
      style={{ textAlign: "center", paddingTop: "40vh" }}
      className={`${styles.page} ${reveal ? styles.reveal : ``}`}
    >
      <SEO title="404: Not found" />
      <img
        className={styles.leftTree}
        src={LeftTree}
        alt={`Tree with squirrel`}
        width="309"
        height="490"
      />
      <img
        className={styles.rightTree}
        src={RightTree}
        alt={`Tree`}
        width="225"
        height="369"
      />
      <div className={styles.logo}>
        <NestaLogo />
      </div>
      <h1>404: Not Found</h1>
      <UnderlineBtn
        to={`/`}
        exit={{
          length: 0,
          zIndex: 5,
        }}
        entry={{
          delay: 0,
          zIndex: 10,
        }}
      >
        <span>Back to Home</span>
      </UnderlineBtn>

      <div className={styles.houses}>
        <img
          className={`${styles.house} ${styles.houseMobile}`}
          src={House1}
          alt={`Coloured Houses`}
          width="375"
          height="139"
        />
        <img
          className={`${styles.house} ${styles.houseDesktop}`}
          src={House2}
          alt={`Coloured Houses`}
          width="1800"
          height="363"
        />
      </div>
    </div>
  )
}

export default NotFoundPage
